export default {
  description: {
    id: 'buyback_my_sales_bank_details_modal_birthdate_view_description',
    defaultMessage: 'Add your personal information',
  },
  warning: {
    id: 'buyback_my_sales_bank_details_modal_birthdate_view_warning',
    defaultMessage:
      'We need the following information to complete your transaction. Please note that you must be at least 18 years old to trade in a device.',
  },
  previewDescription: {
    id: 'buyback_my_sales_bank_details_modal_birthdate_view_preview_description',
    defaultMessage:
      'You have already entered the following personal information',
  },
  previewLabel: {
    id: 'buyback_my_sales_bank_details_modal_birthdate_view_input',
    defaultMessage: 'Date of birth',
  },
  inputLabel: {
    id: 'buyback_my_sales_kyc_details_modal_birthdate_view_input',
    defaultMessage: 'Your date of birth',
  },
  ageError: {
    id: 'buyback_my_sales_kyc_details_modal_birthdate_view_error',
    defaultMessage: 'You must be born after 1901 and be at least 18',
  },
  legalText: {
    id: 'buyback_address_legal_text',
    defaultMessage:
      'This information allows Back Market, the refurbisher and our payment provider to process your trade-in. To find out more and exercise your rights: {link, html}',
  },
  legalLinkText: {
    id: 'buyback_common_legal_link_text',
    defaultMessage: 'Our data protection policy',
  },
}
