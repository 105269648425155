<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    :has-back-button="showBackButton"
    :name
    :title="activeStepTitle"
    @back="goToPrevStep"
    @close="handleClose"
  >
    <template #body>
      <Intro
        v-if="activeStep === 'intro'"
        :has-identity
        :has-identity-informations
        :hasBankDetails
        :hasBirthdate
        :paymentTypes="paymentInfosData?.map((section) => section.type)"
        @next="(id) => goToNextStep(id)"
      />
      <BirthdateDetails
        v-if="activeStep === 'birthdate'"
        :id="props.id"
        :form-id="FORM_IDS.BIRTHDATE"
        :hasBirthdate
        :show-form="showBirthdateForm"
        @submit="handleBirthdateSubmit"
      />
      <BankDetails
        v-if="activeStep === 'bankDetails'"
        :bank="banks"
        :bank-data="bankDetails"
        :form-id="FORM_IDS.BANK_DETAILS"
        :isPending="isBankDetailsLoading"
        :show-form="showBankForm"
        @submit="handleBankSubmit"
      />
      <Identity
        v-if="activeStep === 'identity'"
        :form-id="FORM_IDS.IDENTITY"
        :identity
        :show-form="showIdentityForm"
        :step="identityStep"
        @submit="handleIdentitySubmit"
      />
      <IdentityInformations
        v-if="activeStep === 'identityInformations'"
        :form-id="FORM_IDS.IDENTITY_INFORMATION"
        :identity-infos
        :show-form="showIdentityInformationsForm"
        :step="identityInformationsStep"
        @last-step="handleLastStepIdentityInformations"
        @submit="handleIdentityInformations"
      />
    </template>
    <template #footer>
      <RevButton
        full-width="always"
        :loading="isLoading"
        variant="primary"
        @click="handleFooterBtn"
      >
        {{ footerBtnLabel }}
      </RevButton>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { HttpApiError } from '@backmarket/http-api'
import {
  type BankDetailsResponse,
  PAYMENT_INFOS_TYPES,
  type PaymentInfoResponse,
  postIdentityDocuments,
  setCustomerBirthdate,
} from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { putBankDetails } from '@backmarket/http-api/src/api-specs-payout/client/missing-specs'
import BankDetails from '@backmarket/nuxt-layer-buyback/components/BankDetails/BankDetails.vue'
import type { FormValues } from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import {
  type UnformattedIdentityPayload,
  identityPayloadAdapter,
} from '@backmarket/nuxt-layer-buyback/components/Identity/Identity.utils'
import Identity from '@backmarket/nuxt-layer-buyback/components/Identity/Identity.vue'
import {
  type IdDocumentsType,
  KYC_IDS,
  type KycType,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'
import { RevDrawer } from '@ds/components/Drawer'
import { submitForm } from '@ds/components/Form'
import { closeModal } from '@ds/components/ModalBase'

import {
  useBankDetails,
  useKycInformations,
} from '../../../composables/useKycInformations'

import translations from './KycDetailsModal.translations'
import BirthdateDetails from './birthdate/BirthdateDetails.vue'
import IdentityInformations from './identityInformations/IdentityInformations.vue'
import Intro from './intro/Intro.vue'

type KycDetailsStep = KycType | 'intro'

const MODAL_TITLE = {
  intro: translations.introTitle,
  [KYC_IDS.BANK_DETAILS]: translations.bankDetailsTitle,
  [KYC_IDS.IDENTITY]: translations.identityTitle,
  [KYC_IDS.IDENTITY_INFORMATIONS]: translations.identityInformationsTitle,
  [KYC_IDS.BIRTHDATE]: translations.birthdateTitle,
}

const FORM_IDS = {
  BANK_DETAILS: 'banking-form',
  IDENTITY: 'identity-form',
  IDENTITY_INFORMATION: 'identity-information',
  BIRTHDATE: 'birthdate-form',
}

const props = defineProps<{
  name: string
  id: string
}>()

const identityStep = ref<'choice' | 'details'>('choice')
const identityInformationsStep = ref<'choice' | 'details'>('choice')
const activeStep = ref<KycDetailsStep>('intro')
const i18n = useI18n()
const logger = useLogger()
const emit = defineEmits(['is-loaded', 'close'])
const { openErrorToast, openSuccessToast } = useTheToast()
const isLoading = ref(false)
const editMode = ref(false)
const paymentInfosData = ref<PaymentInfoResponse | null>()
const bankDetails = ref<BankDetailsResponse | null>(null)
const isBankDetailsLoading = ref(false)

const $httpFetchV2 = useHttpFetch()

function fetchData() {
  useKycInformations(props.id).then(
    ({ error: paymentInfoError, data: paymentInfoData }) => {
      if (paymentInfoError.value) {
        openErrorToast({
          title: i18n(translations.toastErrorTitle),
          content: i18n(translations.toastErrorContent),
        })

        logger.error('[BUYBACK][API][KYC] Error get customer payment info', {
          error: paymentInfoError.value,
          owners: ['bot-squad-circularity-order-lifecycle-front'],
        })
      } else {
        paymentInfosData.value = paymentInfoData.value
      }
      emit('is-loaded')
    },
  )
}

fetchData()

const birthdate = computed(() =>
  paymentInfosData.value?.find(
    ({ type }) => type === PAYMENT_INFOS_TYPES.DATE_OF_BIRTH,
  ),
)
const identity = computed(() =>
  paymentInfosData.value?.find(
    ({ type }) => type === PAYMENT_INFOS_TYPES.IDENTITY_DOCUMENT,
  ),
)
const identityInfos = computed(() =>
  paymentInfosData.value?.find(
    ({ type }) => type === PAYMENT_INFOS_TYPES.PERSONAL_IDENTIFICATION,
  ),
)
const banks = computed(() =>
  paymentInfosData.value?.find(
    ({ type }) => type === PAYMENT_INFOS_TYPES.BANK_DETAILS,
  ),
)

const hasBirthdate = computed(() => Boolean(birthdate.value?.filled))
const hasIdentity = computed(() => Boolean(identity.value?.filled))
const hasIdentityInformations = computed(() =>
  Boolean(identityInfos.value?.filled),
)
const hasBankDetails = computed(() => Boolean(banks.value?.filled))
const showBackButton = computed(() => activeStep.value !== 'intro')
const showBirthdateForm = computed(
  () =>
    activeStep.value === 'birthdate' && (editMode.value || !hasBirthdate.value),
)
const showIdentityForm = computed(
  () =>
    activeStep.value === 'identity' && (editMode.value || !hasIdentity.value),
)
const showBankForm = computed(
  () =>
    activeStep.value === 'bankDetails' &&
    (editMode.value || !hasBankDetails.value),
)
const showIdentityInformationsForm = computed(
  () =>
    activeStep.value === 'identityInformations' &&
    (editMode.value || !hasIdentityInformations.value),
)
const activeStepTitle = computed(() => {
  const title = MODAL_TITLE[activeStep.value]
  if (title) {
    return i18n(MODAL_TITLE[activeStep.value])
  }

  return ''
})

const closeBtn = computed(() => activeStep.value === 'intro')
const nextBtn = computed(
  () =>
    (showIdentityForm.value && identityStep.value === 'choice') ||
    (showIdentityInformationsForm.value &&
      identityInformationsStep.value === 'choice'),
)
const saveBtn = computed(
  () =>
    showBirthdateForm.value ||
    showIdentityForm.value ||
    showBankForm.value ||
    showIdentityInformationsForm.value,
)
const footerBtnLabel = computed(() => {
  if (closeBtn.value) {
    return i18n(translations.close)
  }
  if (nextBtn.value) {
    return i18n(translations.next)
  }

  if (saveBtn.value) {
    return i18n(translations.save)
  }

  return i18n(translations.update)
})

function handleClose() {
  emit('close')
}

function goToIntroStep() {
  identityStep.value = 'choice'
  identityInformationsStep.value = 'choice'
  editMode.value = false
  activeStep.value = 'intro'
}

function goToPrevStep() {
  if (activeStep.value === 'identity' && identityStep.value === 'details') {
    identityStep.value = 'choice'
  } else if (
    activeStep.value === 'identityInformations' &&
    identityInformationsStep.value === 'details' &&
    identityInfos.value?.options.length !== 1
  ) {
    identityInformationsStep.value = 'choice'
  } else {
    goToIntroStep()
  }
}

async function goToNextStep(id: KycDetailsStep) {
  activeStep.value = id

  if (id === 'bankDetails') {
    isBankDetailsLoading.value = true
    const { data } = await useBankDetails()
    if (data.value) {
      bankDetails.value = data.value
    }
    isBankDetailsLoading.value = false
  }
}

function handleFooterBtn() {
  if (closeBtn.value) {
    closeModal()
  } else if (nextBtn.value) {
    identityStep.value = 'details'
    identityInformationsStep.value = 'details'
  } else if (saveBtn.value) {
    switch (activeStep.value) {
      case 'birthdate':
        submitForm(FORM_IDS.BIRTHDATE)
        break
      case 'bankDetails':
        submitForm(FORM_IDS.BANK_DETAILS)
        break
      case 'identity':
        submitForm(FORM_IDS.IDENTITY)
        break
      case 'identityInformations':
        submitForm(FORM_IDS.IDENTITY_INFORMATION)
        break

      default:
    }
  } else {
    editMode.value = true
  }
}

async function handleBirthdateSubmit(values: FormValues) {
  isLoading.value = true
  try {
    await $httpFetch(setCustomerBirthdate, {
      pathParams: {
        id: props.id,
      },
      body: {
        birthdate: values.birthdate,
      },
    })
    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })
    fetchData()
    goToIntroStep()
  } catch (err) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })

    logger.error('[BUYBACK][API][KYC] Error updating customer birthdate', {
      error: err as HttpApiError,
      owners: ['bot-squad-circularity-order-lifecycle-front'],
    })
  } finally {
    isLoading.value = false
  }
}

async function handleBankSubmit(values: FormValues) {
  try {
    isLoading.value = true
    const body = new FormData()

    for (const [key, value] of Object.entries(values)) {
      if (value) {
        body.append(key, value.toString())
      }
    }

    await $httpFetch(putBankDetails, {
      body,
    })

    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })
    fetchData()
    goToIntroStep()
  } catch (err) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })

    logger.error('[BUYBACK][API][KYC] Error updating customer bank details', {
      error: err as HttpApiError,
      owners: ['bot-squad-circularity-order-lifecycle-front'],
    })
  } finally {
    isLoading.value = false
  }
}

async function handleIdentitySubmit(
  values: UnformattedIdentityPayload,
  type: IdDocumentsType,
) {
  isLoading.value = true
  const apiPayload = identityPayloadAdapter(values, type)
  const body = new FormData()

  for (const [key, value] of Object.entries(apiPayload)) {
    if (value) {
      body.append(key, value as File)
    }
  }
  try {
    await $httpFetch(postIdentityDocuments, {
      body,
    })

    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })

    fetchData()
    goToIntroStep()
  } catch (err) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })

    logger.error(
      '[BUYBACK][API][KYC] Error updating customer identity documents',
      {
        error: err as HttpApiError,
        owners: ['bot-squad-circularity-order-lifecycle-front'],
      },
    )
  } finally {
    isLoading.value = false
  }
}

async function handleIdentityInformations(idNumber: string, idType: string) {
  isLoading.value = true
  try {
    await $httpFetchV2('/payout/customer/identity-number', {
      method: 'POST',
      body: {
        idType,
        idNumber,
      },
    })
    openSuccessToast({
      title: i18n(translations.toastBankStepTitle),
      content: i18n(translations.toastBankStepContent),
    })

    fetchData()
    goToIntroStep()
  } catch (err) {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorContent),
    })

    logger.error(
      '[BUYBACK][API][KYC] Error updating customer identity number',
      {
        error: err as HttpApiError,
        owners: ['bot-squad-circularity-order-lifecycle-front'],
      },
    )
  } finally {
    isLoading.value = false
  }
}

function handleLastStepIdentityInformations() {
  identityInformationsStep.value = 'details'
}
</script>
