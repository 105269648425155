export default {
  description: {
    id: 'buyback_my_sales_bank_details_modal_bank_step_description',
    defaultMessage:
      'This information will let us transfer money into your account securely.',
  },
  previewDescription: {
    id: 'buyback_my_sales_bank_details_modal_bank_step_preview_description',
    defaultMessage:
      'You have already entered the following banking information:',
  },
  agentPreviewDescription: {
    id: 'buyback_admin_order_bank_modal_description',
    defaultMessage:
      'This Back Market customer has already uploaded their bank informations',
  },
  accountNumber: {
    id: 'buyback_merchant_update_ach_account_number',
    defaultMessage: 'Bank account number',
  },
  iban: {
    id: 'buyback_bank_form_iban',
    defaultMessage: 'IBAN',
  },
  routingNumber: {
    id: 'buyback_merchant_update_ach_routing_number',
    defaultMessage: 'Routing number',
  },
  sortCode: {
    id: 'buyback_merchant_update_bacs_sort_code_label',
    defaultMessage: 'Sort Code',
  },
  loadingDetails: {
    id: 'buyback_my_sales_bank_details_modal_loading',
    defaultMessage: 'Loading details',
  },
  legalText: {
    id: 'buyback_bank_legal_text',
    defaultMessage:
      'Back Market collects your banking information for your payment via our secure service provider. To find out more and exercise your rights: {link, html}',
  },
  legalLinkText: {
    id: 'buyback_common_legal_link_text',
    defaultMessage: 'Our data protection policy',
  },
}
