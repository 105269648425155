export default {
  title: {
    id: 'buyback_my_sales_kyc_id_title',
    defaultMessage: 'Upload a color copy of your ID',
  },
  alreadySentTitle: {
    id: 'myprofile_upload_id_fullfiled_description',
    defaultMessage: 'You have already sent an ID, all good!',
  },
  agentTitle: {
    id: 'buyback_agent_kyc_id_title',
    defaultMessage: 'Upload a color copy of the customer ID',
  },
  agentAlreadySentTitle: {
    id: 'myprofile_agent_id_fullfiled_description',
    defaultMessage:
      'This Back Market customer has already uploaded a copy of their ID',
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'myprofile_id_preview_button',
  },
  update: {
    defaultMessage: 'Update',
    id: 'myprofile_upload_id_fullfiled_button',
  },
  subTitle: {
    id: 'buyback_my_sales_kyc_id_subtitle',
    defaultMessage:
      'Make sure that the name on your ID is exactly the same as the name on your Back Market account. If it’s not, be sure to update the name on your account later.',
  },
  idCard: {
    id: 'buyback_my_sales_kyc_id_tab_id_card',
    defaultMessage: 'Id Card',
  },
  drivingLicence: {
    id: 'buyback_my_sales_kyc_id_tab_driving_licence',
    defaultMessage: 'Driving Licence',
  },
  passport: {
    id: 'buyback_my_sales_kyc_id_tab_passport',
    defaultMessage: 'Passport',
  },
  front: {
    id: 'buyback_my_sales_kyc_id_input_front',
    defaultMessage: 'Front',
  },
  back: {
    id: 'buyback_my_sales_kyc_id_input_back',
    defaultMessage: 'Back',
  },
  IdentityChoiceFirstText: {
    id: 'buyback_my_sales_kyc_id_choice_first_text',
    defaultMessage: 'We need the following information to be able to pay you.',
  },
  IdentityChoiceSecondText: {
    id: 'buyback_my_sales_kyc_id_choice_second_text',
    defaultMessage:
      'The name on your ID must be identical to that of your Back Market account.',
  },
  IdentityChoiceBmAgentText: {
    id: 'buyback_agent_kyc_id_choice_description',
    defaultMessage:
      'The ID document is required for payment. The name on the ID document must match the name of the Back Market account.',
  },
  IdentityDetailsBmAgentText: {
    id: 'buyback_agent_kyc_id_title',
    defaultMessage: 'Upload a color copy of the customer ID',
  },
  IdentityChoiceQuestion: {
    id: 'buyback_my_sales_kyc_id_choice_question',
    defaultMessage: 'What type of document do you want to upload?',
  },
  legalText: {
    id: 'buyback_address_legal_text',
    defaultMessage:
      'This information allows Back Market, the refurbisher and our payment provider to process your trade-in. To find out more and exercise your rights: {link, html}',
  },
  legalLinkText: {
    id: 'buyback_common_legal_link_text',
    defaultMessage: 'Our data protection policy',
  },
}
