<template>
  <div class="flex h-full flex-col">
    <p class="text-static-default-low body-1">
      {{ title }}
    </p>

    <p v-if="!isBmAgent" class="text-static-default-low body-1 mt-12">
      {{ i18n(translations.subTitle) }}
    </p>

    <h3 class="body-1-bold mt-32">
      {{ label }}
    </h3>

    <div v-for="idDoc in ids" :key="idDoc" class="mt-12">
      <FormGenerator
        v-if="idDoc === id"
        :extra-css="['!gap-28']"
        :form-config="formsConfig[idDoc]"
        :form-id
        :has-submit-button="false"
        @submit="handleSubmit"
      />
    </div>

    <small
      v-if="buybackConfig.legalMentions"
      class="caption mt-24 flex-auto content-end"
    >
      <FormattedMessage
        data-test="partner-content"
        :definition="translations.legalText"
      >
        <template #link>
          <RevLink target="_blank" :to="legalLink">
            {{ i18n(translations.legalLinkText) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'

import { type FormValues } from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import FormGenerator from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.vue'
import {
  ID_DOCUMENTS,
  type IdDocumentsType,
  useBuybackConfig,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useGenerateIdCardForm } from '@backmarket/nuxt-layer-buyback/composables/config/useGenerateIdCardForm'

import translations from './Identity.translations'

const i18n = useI18n()
const currentLocale = useI18nLocale()
const buybackConfig = useBuybackConfig()

const legalLink = `${currentLocale}/legal/data-protection`

const props = defineProps<{
  formId: string
  label: string
  id: IdDocumentsType
  ids: IdDocumentsType[]
  isBmAgent?: boolean
}>()

const emit = defineEmits<{
  submit: [values: FormValues]
}>()

const formsConfig = reactive({
  [ID_DOCUMENTS.ID_CARD]: props.ids.includes(ID_DOCUMENTS.ID_CARD)
    ? useGenerateIdCardForm(ID_DOCUMENTS.ID_CARD)
    : [],
  [ID_DOCUMENTS.PASSPORT]: props.ids.includes(ID_DOCUMENTS.PASSPORT)
    ? useGenerateIdCardForm(ID_DOCUMENTS.PASSPORT)
    : [],
  [ID_DOCUMENTS.DRIVING_LICENCE]: props.ids.includes(
    ID_DOCUMENTS.DRIVING_LICENCE,
  )
    ? useGenerateIdCardForm(ID_DOCUMENTS.DRIVING_LICENCE)
    : [],
})
const title = computed(() =>
  props.isBmAgent
    ? i18n(translations.IdentityDetailsBmAgentText)
    : i18n(translations.title),
)
function handleSubmit(values: FormValues) {
  const data = {}

  if (values.idCardBothSidesFile) {
    Object.assign(data, {
      idCardBothSidesFile: (values.idCardBothSidesFile as File[])[0],
    })
  } else {
    Object.assign(data, {
      idCardFrontFile: (values.idCardFrontFile as File[])[0],
      idCardBackFile: (values.idCardBackFile as File[])[0],
    })
  }

  emit('submit', data)
}
</script>
