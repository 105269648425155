<template>
  <div class="flex h-full flex-col">
    <template v-if="showForm">
      <IdentityChoice
        v-show="step === 'choice'"
        v-model:id="id"
        :isBmAgent
        :radio-options
      />
      <IdentityDetails
        v-show="step === 'details'"
        :id
        :form-id
        :ids
        :isBmAgent
        :label
        :show-form
        @submit="handleSubmit"
      />
    </template>
    <p v-else class="text-static-default-low body-1 mb-3 mt-4">
      {{ i18n(translations.alreadySentTitle) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  PAYMENT_INFOS_OPTIONS,
  type PaymentInfo,
  type PaymentInfosOptionsType,
} from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { type FormValues } from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import {
  ID_DOCUMENTS,
  type IdDocumentsType,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'

import translations from './Identity.translations'
import IdentityChoice from './IdentityChoice.vue'
import IdentityDetails from './IdentityDetails.vue'

const props = defineProps<{
  showForm: boolean
  formId: string
  step: 'choice' | 'details'
  identity: PaymentInfo | undefined
  isBmAgent?: boolean
}>()
const emit = defineEmits<{
  submit: [values: FormValues, type: IdDocumentsType]
}>()
const i18n = useI18n()

function optionToIdDocument(option: PaymentInfosOptionsType | undefined) {
  switch (option) {
    case PAYMENT_INFOS_OPTIONS.ID_CARD:
      return ID_DOCUMENTS.ID_CARD
    case PAYMENT_INFOS_OPTIONS.DRIVING_LICENCE:
      return ID_DOCUMENTS.DRIVING_LICENCE
    case PAYMENT_INFOS_OPTIONS.PASSPORT:
    default:
      return ID_DOCUMENTS.PASSPORT
  }
}

const IS_ONE_SIDE = {
  [ID_DOCUMENTS.PASSPORT]: true,
  [ID_DOCUMENTS.DRIVING_LICENCE]: false,
  [ID_DOCUMENTS.ID_CARD]: false,
}

const OPTIONS_TITLES = {
  [ID_DOCUMENTS.PASSPORT]: translations.passport,
  [ID_DOCUMENTS.DRIVING_LICENCE]: translations.drivingLicence,
  [ID_DOCUMENTS.ID_CARD]: translations.idCard,
}

const radioOptions = computed(() => {
  return (
    props.identity?.options.map((option) => {
      const idDocument = optionToIdDocument(option)

      return {
        id: idDocument,
        label: i18n(OPTIONS_TITLES[idDocument]),
        isOneSide: IS_ONE_SIDE[idDocument],
      }
    }) || []
  )
})
const id = ref<IdDocumentsType>(radioOptions.value[0]?.id)
const ids = radioOptions.value.map((option) => option.id)
const label = computed(
  () =>
    radioOptions.value.find((option) => option.id === id.value)?.label || '',
)

function handleSubmit(values: FormValues) {
  emit('submit', values, id.value)
}
</script>
